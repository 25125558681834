import React from 'react'
import { Button, Card, Col, Form, Input, Row } from 'antd'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { toast } from 'sonner'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type IUpdatePassword } from 'Util/Interface'
import { setLoader } from 'Store/slices/commonSlice'

const Profile: React.FC = () => {
  const [form] = Form.useForm()

  const dispatch = useAppDispatch()

  const handleUpdatePassword = async (param: IUpdatePassword) => {
    try {
      dispatch(setLoader(true))
      const response = await Http({
        url: `/update`,
        method: HTTP_METHOD.POST,
        data: param,
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        dispatch(setLoader(false))
        form.resetFields()
      } else {
        dispatch(setLoader(false))
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      dispatch(setLoader(false))
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Update Password</h3>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Row justify="center" align="middle">
            <Col lg={10} md={12} sm={24} xs={24}>
              <Form
                form={form}
                autoComplete="off"
                onFinish={(param: IUpdatePassword) => {
                  void handleUpdatePassword(param)
                }}
                layout="vertical"
                requiredMark="optional"
              >
                <Form.Item
                  label="Old Password"
                  name="old_password"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Old Password!',
                    },
                  ]}
                >
                  <Input.Password className="custom-input" placeholder="Enter Old Password" />
                </Form.Item>

                <Form.Item
                  label="New Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter New Password!',
                    },
                  ]}
                >
                  <Input.Password className="custom-input" placeholder="Enter New Password" />
                </Form.Item>

                <Form.Item
                  label="Confirm Password"
                  name="password_confirmation"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Confirm Password!',
                    },
                  ]}
                >
                  <Input.Password className="custom-input" placeholder="Enter Confirm Password" />
                </Form.Item>

                {/* 
                <Form.Item
                  label="Id"
                  name="recordId"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Record Id!',
                      type: 'string',
                    },
                  ]}
                  hidden={true}
                >
                  <Input className="custom-input" placeholder="Enter Record Id" />
                </Form.Item> */}

                <Button type="primary" htmlType="submit" className="mt-3">
                  Update Now
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  )
}

export default Profile
