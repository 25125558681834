import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from 'Hooks/ReduxHook'
import { type WithChildrenProps } from 'Util/WithChildrenProps'

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token)
  return token ? <>{children}</> : <Navigate to="/login" replace />
}

export default RequireAuth
