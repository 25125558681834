import { Divider, Row, Descriptions, Card, Tag, Image } from 'antd'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { type AxiosError } from 'axios'
import { toast } from 'sonner'
import { useDispatch } from 'react-redux'
import { type IPhoneBook } from 'Util/Interface'
import dayjs from 'dayjs'

const UserDetail = () => {
  const [userData, setuserData] = useState<IPhoneBook>({
    id: '',
    samaj: '',
    familyId: '',
    name: '',
    middleName: '',
    surName: '',
    bloodGroup: '',
    gender: '',
    dateOfBirth: '',
    mobileNumber: '',
    email: '',
    relationShip: '',
    occupation: '',
    helpBlood: '',
    donateOrgans: '',
    donateEyes: '',
    donateBody: '',
    mobileNumberStatus: '',
    address: '',
    addressLink: '',
    marriageAnniversary: '',
    education: '',
    nativePlace: '',
    nativePlaceTaluka: '',
    nativePlaceDistrict: '',
    maternalSurname: '',
    maternalVillage: '',
    maternalVillageTaluka: '',
    maternalVillageDistrict: '',
    businessJobName: '',
    businessAddress: '',
    businessMobile: '',
    businessDescription: '',
    businessEmail: '',
    website: '',
    profileImage: '',
    status: 0,
    remember_token: '',
    created_at: '',
    updated_at: '',
  })
  const params = useParams()
  const dispatch = useDispatch()

  const fetch = useCallback(
    async (recordId: string) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/phonebook/${recordId}`)
        if (response.data?.status && response?.data?.code === 200) {
          dispatch(setLoader(false))
          setuserData(response.data?.data)
        } else {
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        dispatch(setLoader(false))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (params?.id) {
      void fetch(params?.id)
    }
  }, [fetch, params?.id])

  return (
    <Card
      className="card-wrapper"
      title={
        <div className="row justify-between">
          <div>
            <h3>Phonebook Detail</h3>
          </div>
        </div>
      }
      style={{ padding: '1.25rem 1.25rem 0' }}
    >
      <Row>
        <Descriptions title="Profile Image">
          <Descriptions.Item label="">
            <div className="row justify-center items-center">
              {userData?.profileImage ? (
                <Image src={`${ATTCHMENT_BASE_URL}${String(userData?.profileImage)}`} alt="profile" height={100} width={100} />
              ) : (
                <div style={{ background: 'grey', height: 100, width: 100 }}></div>
              )}
            </div>
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Descriptions title="Basic Detail">
          <Descriptions.Item label="First Name">{userData?.name}</Descriptions.Item>
          <Descriptions.Item label="Middle Name">{userData?.middleName}</Descriptions.Item>
          <Descriptions.Item label="Last Name">{userData?.surName}</Descriptions.Item>
          <Descriptions.Item label="Date of Birth">{dayjs(userData?.dateOfBirth).format('MM/DD/YYYY')}</Descriptions.Item>
          <Descriptions.Item label="Email">{userData?.email}</Descriptions.Item>
          <Descriptions.Item label="Phone No">{userData?.mobileNumber}</Descriptions.Item>
          <Descriptions.Item label="Gender">{userData?.gender}</Descriptions.Item>
          <Descriptions.Item label="Blood Group">{userData?.bloodGroup}</Descriptions.Item>
          <Descriptions.Item label="Samaj">{userData?.samaj}</Descriptions.Item>
          <Descriptions.Item label="Family Id">{userData?.familyId}</Descriptions.Item>
          <Descriptions.Item label="Relationship">{userData?.relationShip}</Descriptions.Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Descriptions title="Personal Detail">
          <Descriptions.Item label="Address">{userData?.address}</Descriptions.Item>
          <Descriptions.Item label="Marriage Anniversory">{userData?.marriageAnniversary}</Descriptions.Item>
          <Descriptions.Item label="Native Place">{userData?.nativePlace}</Descriptions.Item>
          <Descriptions.Item label="Education">{userData?.education}</Descriptions.Item>
          <Descriptions.Item label="Native Taluka">{userData?.nativePlaceTaluka}</Descriptions.Item>
          <Descriptions.Item label="Native District">{userData?.nativePlaceDistrict}</Descriptions.Item>
          <Descriptions.Item label="Maternal Surname">{userData?.maternalSurname}</Descriptions.Item>
          <Descriptions.Item label="Maternal Village">{userData?.maternalVillage}</Descriptions.Item>
          <Descriptions.Item label="Maternal Taluka">{userData?.maternalVillageTaluka}</Descriptions.Item>
          <Descriptions.Item label="Maternal District">{userData?.maternalVillageDistrict}</Descriptions.Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Descriptions title="Family Detail">
          <Descriptions.Item label="Bussiness Name">{userData?.businessJobName}</Descriptions.Item>
          <Descriptions.Item label="Bussiness Address">{userData?.businessAddress}</Descriptions.Item>
          <Descriptions.Item label="Bussiness Mobile">{userData?.businessMobile}</Descriptions.Item>
          <Descriptions.Item label="Bussiness Description">{userData?.businessDescription}</Descriptions.Item>
          <Descriptions.Item label="Bussiness Email">{userData?.businessEmail}</Descriptions.Item>
          <Descriptions.Item label="Bussiness Website">{userData?.website}</Descriptions.Item>
          <Descriptions.Item label="Is Active">
            <Tag color={Number(userData.status) === 1 ? 'green' : 'red'}>{Number(userData.status) === 1 ? 'Active' : 'InActive'}</Tag>
          </Descriptions.Item>
        </Descriptions>
      </Row>
    </Card>
  )
}

export default UserDetail
