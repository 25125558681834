import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag, Image, Col, Row, Input } from 'antd'
import Table, { type TablePaginationConfig, type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { setLoader } from 'Store/slices/commonSlice'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { DeleteOutlined, EditOutlined, ExportOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { toast } from 'sonner'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type IPagination, type IPhoneBook } from 'Util/Interface'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import { useNavigate } from 'react-router-dom'
import useDebounce from 'Hooks/useDebounce'
import { exportToExcel } from 'Util/Utils'

const { Search } = Input

const PhoneBook: React.FC = () => {
  const [pagination, setPagination] = useState<IPagination>({ limit: 10, page: 0 })
  const [tableData, setTableData] = useState<IPhoneBook[]>([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const debouncedSearchTerm = useDebounce(search, 1000)

  const fetch = useCallback(
    async (params: IPagination) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/phonebook?${debouncedSearchTerm && `keyword=${debouncedSearchTerm}&`}limit=${Number(params.limit)}&page=${Number(params.page)}`)
        if (response.data?.status && response?.data?.code === 200) {
          dispatch(setLoader(false))
          setTableData(response?.data?.data?.data)
          setPagination({ limit: params.limit, page: params.page, total: response.data?.total })
        } else {
          setTableData([])
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        setTableData([])
        dispatch(setLoader(false))
      }
    },
    [debouncedSearchTerm, dispatch]
  )

  const downloadRecord = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      setLoading(true)
      const response = await Http(`/phonebook?limit=${10000}`)
      if (response.data?.status && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setLoading(false)
        const excelData: any[] = []
        response?.data?.data?.data?.map(
          (item: {
            name: string
            middleName: string
            surName: string
            mobileNumber: string
            dateOfBirth: string
            familyId: string
            bloodGroup: string
            address: string
            samaj: string
          }) => {
            const obj = {
              Name: item?.name,
              'Middle Name': item?.middleName,
              'Sur Name': item?.surName,
              'Mobile number': item?.mobileNumber,
              'Date of birth': item?.dateOfBirth,
              'Family id': item?.familyId,
              'Blood Group': item?.bloodGroup,
              Address: item?.address,
              Samaj: item?.samaj,
            }
            excelData.push(obj)
            return item
          }
        )
        exportToExcel(excelData, 'phonebook')
      } else {
        dispatch(setLoader(false))
        setLoading(false)
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')

      dispatch(setLoader(false))
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    void fetch({ limit: 10, page: 1 })
  }, [fetch])

  const handleDelete = async (params: IPhoneBook) => {
    try {
      const response = await Http(`/phonebook/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch({ limit: pagination.limit, page: pagination.page })
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpsert = async (recordId: string, status: number) => {
    try {
      const response = await Http({
        url: `/phonebook-toggle/${recordId}`,
        method: HTTP_METHOD.PUT,
        data: {
          status,
        },
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch({ limit: pagination.limit, page: pagination.page })
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const columns: ColumnsType<IPhoneBook> = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'id',
      render: (value, item, index) => (1 - 1) * 10 + index + 1,
    },
    {
      title: 'Profile Pic',
      dataIndex: 'profile',
      key: 'profile',
      render: (value, record: IPhoneBook) => {
        return (
          <>
            {record?.profileImage ? (
              <Image src={`${ATTCHMENT_BASE_URL}${String(record?.profileImage)}`} alt="profile" height={100} width={100} />
            ) : (
              <div style={{ background: 'grey', height: 100, width: 100 }}></div>
            )}
          </>
        )
      },
    },
    {
      title: 'Family Id',
      dataIndex: 'familyId',
      key: 'familyId',
    },
    {
      title: 'Samaj',
      dataIndex: 'samaj',
      key: 'samaj',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (_value, record: IPhoneBook) => {
        return (
          <>
            <div>
              <strong>FullName: </strong> {`${record?.name} ${record?.middleName} ${record?.surName}`}
            </div>
            <div>
              <strong>Email: </strong> {record?.email}
            </div>
            <div>
              <strong>Phone: </strong> {record?.mobileNumber}
            </div>
          </>
        )
      },
    },
    {
      title: 'Blood Group',
      key: 'bloodGroup',
      dataIndex: 'bloodGroup',
    },
    {
      title: 'Gender',
      key: 'gender',
      dataIndex: 'gender',
    },
    {
      title: 'Date Of Birth',
      key: 'dateOfBirth',
      dataIndex: 'dateOfBirth',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 1 ? 'green' : 'red'}>{value === 1 ? 'Active' : 'Inactive'}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: IPhoneBook) => {
        return (
          <Space>
            {record.status === 1 ? (
              <Button
                onClick={() => {
                  void handleUpsert(record.id, 0)
                }}
              >
                Deactive
              </Button>
            ) : (
              <Button
                onClick={() => {
                  void handleUpsert(record.id, 1)
                }}
              >
                Active
              </Button>
            )}
            <Button
              icon={<InfoCircleOutlined />}
              onClick={() => {
                navigate(`/phonebook/${record?.id}`)
              }}
            >
              View
            </Button>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                navigate(`/phonebook/edit/${record?.id}`)
              }}
            >
              Edit
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const handleTableChange = (params: TablePaginationConfig) => {
    const limit = params.pageSize ?? pagination.limit
    const page = Number(params.current)
    setPagination({ ...pagination, limit, page })
    void fetch({ limit, page })
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>PhoneBook List</h3>
              </div>
              <Space>
                <div>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('/phonebook/add')
                    }}
                  >
                    Add PhoneBook
                  </Button>
                </div>
                <div>
                  <Button
                    type="primary"
                    icon={<ExportOutlined />}
                    onClick={() => {
                      void downloadRecord()
                    }}
                    loading={loading}
                  >
                    Export
                  </Button>
                </div>
              </Space>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Row className="mb-5">
            <Col lg={10} sm={15} xs={24}>
              <Search placeholder="Search by Name, Family Id, Mobile Number" allowClear onChange={onSearchChange} className="custom-searchbar" />
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={pagination}
            onChange={handleTableChange}
            rowKey={(dataIndex: IPhoneBook) => dataIndex?.id}
            bordered
            size="middle"
          />
        </Card>
      </div>
    </>
  )
}

export default PhoneBook
