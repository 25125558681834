import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Form, Input, Modal, Select, Tag, type UploadProps, Row, Upload } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { setLoader } from 'Store/slices/commonSlice'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { DeleteOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons'
import { toast } from 'sonner'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type IAddMagzine, type IMagzine } from 'Util/Interface'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'

const Magzine: React.FC = () => {
  const [tableData, setTableData] = useState<IMagzine[]>([])
  const [visible, setVisble] = useState(false)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [updateId, setUpdateId] = useState<string>('')

  const [updateUploadImg, setUpdateUploadImg] = useState<any>('')
  const [uploadImg, setUploadImg] = useState<any>('')

  const [form] = Form.useForm()

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/magzine-list`)
      if (response.data?.status && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleDelete = async (params: IMagzine) => {
    try {
      const response = await Http(`/magzine/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpsert = async (param: IAddMagzine) => {
    try {
      if (param?.magzinePdf && Number(param?.magzinePdf[0]?.size) >= 10007822) {
        toast.error('Upload File Less Than 10 MB')
        return false
      }

      const formData = new FormData()
      param?.magnizeName && formData.append('magzineName', param?.magnizeName)
      param?.status && formData.append('status', String(param?.status))

      if (param?.magzinePdf && param?.magzinePdf[0]?.originFileObj) {
        formData.append('magzinePdf', param?.magzinePdf[0]?.originFileObj)
      }

      const response = await Http({
        url: !isUpdated ? '/magzine' : `/magzine/${updateId}`,
        method: HTTP_METHOD.POST,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        if (!isUpdated) {
          form.resetFields()
        }
        setVisble(false)
        setIsUpdated(false)
        setUpdateId('')
        setUploadImg('')
        setUpdateUploadImg('')
        void fetch()
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpdate = (param: IMagzine) => {
    setIsUpdated(true)
    setUpdateId(param.id)
    form.setFieldsValue({
      magnizeName: param?.magnizeName,
      status: param?.status,
    })
    setUpdateUploadImg(param.magnizePdf)
    setVisble(true)
  }

  const handleCancel = () => {
    setVisble(false)
    setIsUpdated(false)
    setUpdateId('')
    setUpdateUploadImg('')
    form.resetFields()
  }

  const columns: ColumnsType<IMagzine> = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'id',
      render: (value, item, index) => (1 - 1) * 10 + index + 1,
    },
    {
      title: 'Magzine Name',
      dataIndex: 'magnizeName',
      key: 'magnizeName',
    },
    {
      title: 'Magzine',
      dataIndex: 'magnizePdf',
      key: 'magnizePdf',
      render: (value) => {
        return (
          <>
            {value ? (
              <iframe title="Magzine" height={100} width={100} src={`${ATTCHMENT_BASE_URL}${String(value)}`}></iframe>
            ) : (
              <div style={{ background: 'grey', height: 100, width: 100 }}></div>
            )}
          </>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 1 ? 'green' : 'red'}>{value === 1 ? 'Active' : 'Inactive'}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: IMagzine) => {
        return (
          <Space>
            <Button
              icon={<FormOutlined />}
              onClick={() => {
                handleUpdate(record)
              }}
            >
              Update
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const props: UploadProps = {
    accept: 'application/pdf',
    name: 'thumbnail',
    // listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: false,
    maxCount: 1,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (Number(file?.size) >= 10007822) {
        toast.error('Upload File Less Than 10 MB')
        return false
      }
      if (type !== 'pdf') {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      } else {
        return true
      }
    },
    customRequest: (info) => {
      setUploadImg(info?.file)
    },
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Magzine List</h3>
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setVisble(true)
                  }}
                >
                  Add Magzine
                </Button>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: IMagzine) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
      <Modal
        title={!isUpdated ? 'Add Magzine' : 'Update Magzine'}
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Space key={'action'}>
            <Button type="primary" onClick={form.submit} className="btn-padding">
              Save
            </Button>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
          </Space>,
        ]}
        width={600}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          onFinish={(param: IAddMagzine) => {
            void handleUpsert(param)
          }}
          requiredMark="optional"
        >
          <Form.Item
            label="Magzine Name"
            name="magnizeName"
            rules={[
              {
                required: true,
                message: 'Please Enter Magzine Name!',
                type: 'string',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter Magzine Name" />
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: 'Select Status!',
              },
            ]}
          >
            <Select
              placeholder="Select Status"
              className="custom-select"
              options={[
                { value: 1, label: 'Active' },
                { value: 0, label: 'Inactive' },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Magzine"
            name="magzinePdf"
            rules={[
              {
                required: true,
                message: 'Please Upload Magzine!',
              },
            ]}
            className="custom-input-label mb-6"
            valuePropName="fileList"
            getValueFromEvent={(event) => {
              return event?.fileList
            }}
          >
            <Upload {...props}>{uploadImg?.name ? <Button>{String(uploadImg?.name)}</Button> : <Button> Upload Magzine </Button>}</Upload>
          </Form.Item>
          <Row justify={'center'} className="mb-3">
            {updateUploadImg && isUpdated && <iframe title="Magzine" height={200} width={200} src={`${ATTCHMENT_BASE_URL}${String(updateUploadImg)}`}></iframe>}
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default Magzine
