import React from 'react'
import { AppstoreOutlined, BankOutlined, LineHeightOutlined, PicCenterOutlined, ReadOutlined, SettingOutlined, SolutionOutlined, TeamOutlined } from '@ant-design/icons'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    url: '/',
    key: 'Dashboard',
    icon: <AppstoreOutlined />,
  },
  {
    title: 'PhoneBook',
    url: '/phonebook',
    key: 'phonebook',
    icon: <TeamOutlined />,
  },
  {
    title: 'Business',
    url: '/bussiness',
    key: 'bussiness',
    icon: <SolutionOutlined />,
  },
  {
    title: 'App Feature',
    url: '/feature',
    key: 'feature',
    icon: <BankOutlined />,
  },
  {
    title: 'Feed',
    url: '/feed',
    key: 'feed',
    icon: <SolutionOutlined />,
  },
  {
    title: 'Songs',
    url: '/song',
    key: 'song',
    icon: <SolutionOutlined />,
  },
  {
    title: 'Magzine',
    url: '/magzine',
    key: 'magzine',
    icon: <SolutionOutlined />,
  },
  {
    title: 'Newspaper',
    url: '/newspaper',
    key: 'newspaper',
    icon: <SolutionOutlined />,
  },
  {
    title: 'Emergency',
    url: '/emergency',
    key: 'emergency',
    icon: <LineHeightOutlined />,
  },
  {
    title: 'Feedback',
    url: '/feedback',
    key: 'feedback',
    icon: <PicCenterOutlined />,
  },
  {
    title: 'SubVichar',
    url: '/subvichar',
    key: 'subvichar',
    icon: <ReadOutlined />,
  },
  {
    title: 'Explore',
    url: '/explore',
    key: 'explore',
    icon: <SolutionOutlined />,
  },
  {
    title: 'Job Vacancy',
    url: '/job',
    key: 'job',
    icon: <SolutionOutlined />,
  },
  {
    title: 'Receipe',
    url: '/receipe',
    key: 'receipe',
    icon: <SolutionOutlined />,
  },
  {
    title: 'Notification',
    url: '/notification',
    key: 'notification',
    icon: <TeamOutlined />,
  },
  {
    title: 'Guest User',
    url: '/guest-user',
    key: 'guest-user',
    icon: <TeamOutlined />,
  },
  {
    title: 'Setting',
    url: '/setting',
    key: 'Setting',
    icon: <SettingOutlined />,
  },
]
