import { Spin } from 'antd'
import React from 'react'

const MainLoader = () => {
  return (
    <div className="loader-wrapper">
      <Spin size="large" />
    </div>
  )
}

export default MainLoader
