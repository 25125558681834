import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from '../store'

const initialState = {
  loading: false,
}

export const commonSlice = createSlice({
  name: 'commonData',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setLoader } = commonSlice.actions

export const selectCommon = (state: RootState) => state.common

export default commonSlice.reducer
