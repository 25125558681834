import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag, Image, Input, Col, Row } from 'antd'
import Table, { type TablePaginationConfig, type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { setLoader } from 'Store/slices/commonSlice'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { DeleteOutlined, EditOutlined, ExportOutlined, PlusOutlined } from '@ant-design/icons'
import { toast } from 'sonner'
import Http, { type ApiErrorData } from 'Util/Http'
import { type IPagination, type IBusiness, HTTP_METHOD } from 'Util/Interface'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import { useNavigate } from 'react-router-dom'
import useDebounce from 'Hooks/useDebounce'
import { exportToExcel } from 'Util/Utils'

const { Search } = Input

const Business: React.FC = () => {
  const [pagination, setPagination] = useState<IPagination>({ limit: 10, page: 0 })
  const [tableData, setTableData] = useState<IBusiness[]>([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const debouncedSearchTerm = useDebounce(search, 1000)

  const fetch = useCallback(
    async (params: IPagination) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/bussiness?${debouncedSearchTerm && `keyword=${debouncedSearchTerm}&`}limit=${Number(params.limit)}&page=${Number(params.page)}`)
        if (response.data?.status && response?.data?.code === 200) {
          dispatch(setLoader(false))
          setTableData(response.data?.data?.data)
          setPagination({ limit: params.limit, page: params.page, total: response.data?.total })
        } else {
          setTableData([])
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        setTableData([])
        dispatch(setLoader(false))
      }
    },
    [debouncedSearchTerm, dispatch]
  )

  const downloadRecord = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      setLoading(true)
      const response = await Http(`/bussiness?limit=${10000}`)
      if (response.data?.status && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setLoading(false)
        const excelData: any[] = []
        response?.data?.data?.data?.map(
          (item: {
            businessName: string
            businessDescription: string
            businessEmail: string
            businessMobileNo: string
            businessCategory: string
            businessAddressLink: string
            businessAddress: string
          }) => {
            const obj = {
              'Business Name': item?.businessName,
              'Business Description': item?.businessDescription,
              'Business Email': item?.businessEmail,
              'Business Mobile': item?.businessMobileNo,
              'Business Category': item?.businessCategory,
              'Business Address': item?.businessAddress,
              'Address Link': item?.businessAddressLink,
            }
            excelData.push(obj)
            return item
          }
        )
        exportToExcel(excelData, 'business')
      } else {
        dispatch(setLoader(false))
        setLoading(false)
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')

      dispatch(setLoader(false))
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    void fetch({ limit: 10, page: 1 })
  }, [fetch])

  const handleDelete = async (params: IBusiness) => {
    try {
      const response = await Http(`/bussiness/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch({ limit: pagination.limit, page: pagination.page })
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpsert = async (recordId: string, status: number) => {
    try {
      const response = await Http({
        url: `/bussiness-toggle/${recordId}`,
        method: HTTP_METHOD.PUT,
        data: {
          status,
        },
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch({ limit: pagination.limit, page: pagination.page })
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const columns: ColumnsType<IBusiness> = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'id',
      render: (value, item, index) => (1 - 1) * 10 + index + 1,
    },
    {
      title: 'Banner',
      dataIndex: 'mainImage',
      key: 'mainImage',
      render: (value) => {
        return (
          <>
            {value ? (
              <Image src={`${ATTCHMENT_BASE_URL}${String(value)}`} alt="profile" height={100} width={100} />
            ) : (
              <div style={{ background: 'grey', height: 100, width: 100 }}></div>
            )}
          </>
        )
      },
    },
    {
      title: 'Business Name',
      dataIndex: 'businessName',
      key: 'businessName',
      render: (_value, record: IBusiness) => {
        return (
          <>
            <div>
              <strong>Business Name: </strong> {`${record?.businessName}`}
            </div>
            <div>
              <strong>Business Email: </strong> {record?.businessEmail}
            </div>
            <div>
              <strong>Business Phone: </strong> {record?.businessMobileNo}
            </div>
          </>
        )
      },
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      render: (_value, record: IBusiness) => {
        return (
          <>
            <div>
              <strong>FullName: </strong> {`${record?.ownerName1}`}
            </div>
            <div>
              <strong>Email: </strong> {record?.ownerEmail1}
            </div>
            <div>
              <strong>Phone: </strong> {record?.ownerEmail1}
            </div>
          </>
        )
      },
    },
    {
      title: 'Address',
      dataIndex: 'businessAddress',
      key: 'businessAddress',
      render: (value) => <div style={{ wordBreak: 'break-all' }}>{value}</div>,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Donation Amount',
      dataIndex: 'donationAmount',
      key: 'donationAmount',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 1 ? 'green' : 'red'}>{value === 1 ? 'Active' : 'Inactive'}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: IBusiness) => {
        return (
          <Space>
            {record.status === 1 ? (
              <Button
                onClick={() => {
                  void handleUpsert(record.id, 0)
                }}
              >
                Deactive
              </Button>
            ) : (
              <Button
                onClick={() => {
                  void handleUpsert(record.id, 1)
                }}
              >
                Active
              </Button>
            )}
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                navigate(`/bussiness/edit/${record?.id}`)
              }}
            >
              Edit
            </Button>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const handleTableChange = (params: TablePaginationConfig) => {
    const limit = params.pageSize ?? pagination.limit
    const page = Number(params.current)
    setPagination({ ...pagination, limit, page })
    void fetch({ limit, page })
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Business List</h3>
              </div>
              <Space>
                <div>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('/bussiness/add')
                    }}
                  >
                    Add Business
                  </Button>
                </div>
                <div>
                  <Button
                    type="primary"
                    icon={<ExportOutlined />}
                    onClick={() => {
                      void downloadRecord()
                    }}
                    loading={loading}
                  >
                    Export
                  </Button>
                </div>
              </Space>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Row className="mb-5">
            <Col lg={10} sm={15} xs={24}>
              <Search placeholder="Search by Business Name, Family Id, Mobile Number, Donation Amount" allowClear onChange={onSearchChange} className="custom-searchbar" />
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={pagination}
            onChange={handleTableChange}
            rowKey={(dataIndex: IBusiness) => dataIndex?.id}
            scroll={{ x: true }}
            bordered
            size="middle"
          />
        </Card>
      </div>
    </>
  )
}

export default Business
