import React, { useEffect, useRef } from 'react'
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { RequireFullscreen } from 'Hooks/RequireFullscreen'

export const HeaderFullscreen: React.FC = () => {
  const rootRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    rootRef.current = document.getElementById('root')
  }, [])

  return (
    <RequireFullscreen component={rootRef}>
      {(isFullscreen) => (
        <div className="header-action-wrapper ">
          <Button type={isFullscreen ? 'primary' : undefined} icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} />
        </div>
      )}
    </RequireFullscreen>
  )
}
