import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Form, Input, InputNumber, Select, Col, Row, Space, Upload, type UploadProps, Image, DatePicker } from 'antd'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import { type IAddPhoneBook } from 'Util/Interface'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'

const UpsertPhone: React.FC = () => {
  const [profileImg, setProfileImg] = useState<any>('')
  const [uploadProfileImg, setUploadProfileImg] = useState<any>('')
  const [relationShip, setRelationShip] = useState([])
  const [cityList, setCityList] = useState([])
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const params = useParams()
  const navigate = useNavigate()

  const fetchById = useCallback(
    async (recordId: string) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/phonebook/${recordId}`)

        if (response.data?.status && response?.data?.code === 200) {
          dispatch(setLoader(false))
          if (response.data?.data?.profileImage) {
            setProfileImg(response.data?.data?.profileImage)
          }
          form.setFieldsValue({
            firstName: response.data?.data?.name,
            samaj: response?.data?.data?.samaj,
            familyId: response?.data?.data?.familyId,
            middleName: response?.data?.data?.middleName,
            surName: response?.data?.data?.surName,
            bloodGroup: response?.data?.data?.bloodGroup,
            gender: response?.data?.data?.gender,
            dateofBirth: response?.data?.data?.dateOfBirth ? dayjs(response?.data?.data?.dateOfBirth) : '',
            mobileNumber: response?.data?.data?.mobileNumber,
            emailAddress: response?.data?.data?.email,
            relation: response?.data?.data?.relationShip,
            occupation: response?.data?.data?.occupation,
            bloodDonate: response?.data?.data?.helpBlood,
            organDonate: response?.data?.data?.donateOrgans,
            eyeDonate: response?.data?.data?.donateEyes,
            bodyDonate: response?.data?.data?.donateBody,
            nativePlace: response?.data?.data?.nativePlace,
            nativePlaceTaluka: response?.data?.data?.nativePlaceTaluka,
            nativePlaceDistrict: response?.data?.data?.nativePlaceDistrict,
            maternalVillage: response?.data?.data?.maternalVillage,
            maternalVillageTaluka: response?.data?.data?.maternalVillageTaluka,
            maternalVillageDistrict: response?.data?.data?.maternalVillageDistrict,
            businessName: response?.data?.data?.businessJobName,
            businessAddress: response?.data?.data?.businessAddress,
            businessMobile: response?.data?.data?.businessMobile,
            businessDescription: response?.data?.data?.businessDescription,
            businessEmail: response?.data?.data?.businessEmail,
            businessWebsite: response?.data?.data?.website,
            maternalSurname: response?.data?.data?.maternalSurname,
            mobileNumberStatus: response?.data?.data?.mobileNumberStatus,
            address: response?.data?.data?.address,
            addressLink: response?.data?.data?.addressLink,
            marriageAnniversary: response?.data?.data?.marriageAnniversary ? dayjs(response?.data?.data?.marriageAnniversary) : '',
            education: response?.data?.data?.education,
          })
        } else {
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        dispatch(setLoader(false))
      }
    },
    [dispatch, form]
  )

  const fetchMaster = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http('/admin-master')
      if (response.data?.status && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setRelationShip(response.data?.data?.relationship)
        // setBusinessCategory(response.data?.data?.businesscategory)
        setCityList(response.data?.data?.citylist)
      } else {
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (params?.id) {
      void fetchById(params?.id)
    }
    void fetchMaster()
  }, [fetchById, fetchMaster, params?.id])

  const handleUpsert = async (param: IAddPhoneBook) => {
    try {
      const formValue = new FormData()
      param.samaj && param.samaj !== 'null' && formValue.append('samaj', param.samaj)
      param.familyId && param.familyId !== 'null' && formValue.append('familyId', param.familyId)
      param.firstName && param.firstName !== 'null' && formValue.append('firstName', param.firstName)
      param.middleName && param.middleName !== 'null' && formValue.append('middleName', param.middleName)
      param.surName && param.surName !== 'null' && formValue.append('surName', param.surName)
      param.bloodGroup && param.bloodGroup !== 'null' && formValue.append('bloodGroup', param.bloodGroup)
      param.gender && param.gender !== 'null' && formValue.append('gender', param.gender)
      param.dateofBirth && param.dateofBirth !== 'null' && formValue.append('dateofBirth', dayjs(param.dateofBirth).format('YYYY/MM/DD'))
      param.mobileNumber && param.mobileNumber !== 'null' && formValue.append('mobileNumber', param.mobileNumber)
      param.emailAddress && param.emailAddress !== 'null' && formValue.append('emailAddress', param.emailAddress)
      param.relation && param.relation !== 'null' && formValue.append('relation', param.relation)
      param.occupation && param.occupation !== 'null' && formValue.append('occupation', param.occupation)
      param.bloodDonate && param.bloodDonate !== 'null' && formValue.append('bloodDonate', param.bloodDonate)
      param.organDonate && param.organDonate !== 'null' && formValue.append('organDonate', param.organDonate)
      param.eyeDonate && param.eyeDonate !== 'null' && formValue.append('eyeDonate', param.eyeDonate)
      param.bodyDonate && param.bodyDonate !== 'null' && formValue.append('bodyDonate', param.bodyDonate)
      param.nativePlace && param.nativePlace !== 'null' && formValue.append('nativePlace', param.nativePlace)
      param.nativePlaceTaluka && param.nativePlaceTaluka !== 'null' && formValue.append('nativeTaluka', param.nativePlaceTaluka)
      param.nativePlaceDistrict && param.nativePlaceDistrict !== 'null' && formValue.append('nativeDistrict', param.nativePlaceDistrict)
      param.maternalVillage && param.maternalVillage !== 'null' && formValue.append('maternalVillage', param.maternalVillage)
      param.maternalVillageTaluka && param.maternalVillageTaluka !== 'null' && formValue.append('maternalTaluka', param.maternalVillageTaluka)
      param.maternalVillageDistrict && param.maternalVillageDistrict !== 'null' && formValue.append('maternalDistrict', param.maternalVillageDistrict)
      param.businessName && param.businessName !== 'null' && formValue.append('businessName', param.businessName)
      param.businessAddress && param.businessAddress !== 'null' && formValue.append('businessAddress', param.businessAddress)
      param.businessMobile && param.businessMobile !== 'null' && formValue.append('businessMobile', param.businessMobile)
      param.businessDescription && param.businessDescription !== 'null' && formValue.append('businessDescription', param.businessDescription)
      param.businessEmail && param.businessEmail !== 'null' && formValue.append('businessEmail', param.businessEmail)
      param.businessWebsite && param.businessWebsite !== 'null' && formValue.append('businessWebsite', param.businessWebsite)
      param.maternalSurname && param.maternalSurname !== 'null' && formValue.append('maternalSurname', param.maternalSurname)
      param.mobileNumberStatus && param.mobileNumberStatus !== 'null' && formValue.append('mobileNumberStatus', param.mobileNumberStatus)
      param.address && param.address !== 'null' && formValue.append('address', param.address)
      param.addressLink && param.addressLink !== 'null' && formValue.append('addressLink', param.addressLink)
      param.marriageAnniversary && param.marriageAnniversary !== 'null' && formValue.append('marriageAnniversary', dayjs(param.marriageAnniversary).format('YYYY/MM/DD'))
      param.education && param.education !== 'null' && formValue.append('education', param.education)

      if (param?.profileImage) {
        formValue.append('profileImage', param?.profileImage[0].originFileObj)
      }

      const response = await Http({
        url: params?.id ? `/phonebook/${String(params?.id)}` : '/phonebook',
        method: 'POST',
        data: formValue,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        if (!params?.id) {
          form.resetFields()
        }
        setUploadProfileImg('')
        if (params?.id) {
          void fetchById(String(params?.id))
        }
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
      form.resetFields()
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const profileProps: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadProfileImg(info?.file)
    },
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>User Detail</h3>
              </div>
              <div>
                <Space>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('/phonebook')
                    }}
                  >
                    Back
                  </Button>
                </Space>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={(value: IAddPhoneBook) => {
              void handleUpsert(value)
            }}
            requiredMark="optional"
          >
            <Row gutter={[40, 24]}>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Samaj"
                  name="samaj"
                  rules={[
                    {
                      required: true,
                      message: 'Select Samaj!',
                    },
                  ]}
                >
                  <Select placeholder="Select Samaj" className="custom-select">
                    {cityList?.map((item: { cityName: string }, idx: number) => {
                      return (
                        <Select.Option value={item?.cityName} key={idx}>
                          {item?.cityName}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Family Id"
                  name="familyId"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Family Id!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Family Id" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter First Name!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter First Name" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Middle Name"
                  name="middleName"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Middle Name!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Middle Name" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Surname Name"
                  name="surName"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Surname Name!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Surname Name" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Blood Group"
                  name="bloodGroup"
                  rules={[
                    {
                      required: true,
                      message: 'Select Blood Group!',
                    },
                  ]}
                >
                  <Select placeholder="Select Blood Group" className="custom-select">
                    <Select.Option value="A+" selected>
                      A+
                    </Select.Option>
                    <Select.Option value="A-">A-</Select.Option>
                    <Select.Option value="B+">B+</Select.Option>
                    <Select.Option value="B-">B-</Select.Option>
                    <Select.Option value="AB+">AB+</Select.Option>
                    <Select.Option value="AB-">AB-</Select.Option>
                    <Select.Option value="O+">O+</Select.Option>
                    <Select.Option value="O-">O-</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: 'Select Gender!',
                    },
                  ]}
                >
                  <Select placeholder="Select Gender" className="custom-select">
                    <Select.Option value="Male" selected>
                      Male
                    </Select.Option>
                    <Select.Option value="Female">Female</Select.Option>
                    <Select.Option value="Other">Other</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Email Address"
                  name="emailAddress"
                  // rules={[
                  //   {
                  //     required: !params?.id,
                  //     message: 'Please Enter Email Address!',
                  //     type: 'email',
                  //   },
                  // ]}
                >
                  <Input className="custom-input" placeholder="Enter Email Address" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Mobile Number"
                  name="mobileNumber"
                  rules={[
                    {
                      required: !params?.id,
                      message: 'Please Enter Mobile Number!',
                    },
                  ]}
                >
                  <InputNumber className="custom-input-number" minLength={10} maxLength={10} placeholder="Enter Mobile Number" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="RelationShip"
                  name="relation"
                  rules={[
                    {
                      required: true,
                      message: 'Select RelationShip!',
                    },
                  ]}
                >
                  <Select placeholder="Select RelationShip" className="custom-select">
                    {relationShip?.map((item: { relation: string }, idx: number) => {
                      return (
                        <Select.Option value={item?.relation} key={idx}>
                          {item?.relation}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Date of Birth"
                  name="dateofBirth"
                  rules={[
                    {
                      required: true,
                      message: 'Select Date of Birth!',
                    },
                  ]}
                >
                  <DatePicker className="custom-date" placeholder="Select Date of Birth" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Profile Image"
                      name="profileImage"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Image!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...profileProps}>{uploadProfileImg?.name ? <Button>{String(uploadProfileImg?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="ml-4">{profileImg && <Image width={100} src={`${ATTCHMENT_BASE_URL}${String(profileImg)}`} />}</div>
                  </Col>
                </Row>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Occupation" name="occupation">
                  <Input className="custom-input" placeholder="Enter Occupation" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Native Place" name="nativePlace">
                  <Input className="custom-input" placeholder="Enter Native Place" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Native Place Taluka" name="nativePlaceTaluka">
                  <Input className="custom-input" placeholder="Enter Native Place Taluka" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Native Place District" name="nativePlaceDistrict">
                  <Input className="custom-input" placeholder="Enter Native Place District" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Maternal Village" name="maternalVillage">
                  <Input className="custom-input" placeholder="Enter Maternal Village" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Maternal Village Taluka" name="maternalVillageTaluka">
                  <Input className="custom-input" placeholder="Enter Maternal Village Taluka" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Maternal Village District" name="maternalVillageDistrict">
                  <Input className="custom-input" placeholder="Enter Maternal Village District" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Business Job Name" name="businessName">
                  <Input className="custom-input" placeholder="Enter Business Job Name" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Business Address" name="businessAddress">
                  <Input className="custom-input" placeholder="Enter Business Address" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Business Mobile" name="businessMobile">
                  <InputNumber className="custom-input-number" minLength={10} maxLength={10} placeholder="Enter Business Number" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Business Description" name="businessDescription">
                  <Input className="custom-input" placeholder="Enter Business Description" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Business Email" name="businessEmail">
                  <Input className="custom-input" placeholder="Enter Business Email" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Business Website" name="businessWebsite">
                  <Input className="custom-input" placeholder="Enter Business Website" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Maternal Surname" name="maternalSurname">
                  <Input className="custom-input" placeholder="Enter Maternal Surname" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Address" name="address">
                  <TextArea className="custom-input" placeholder="Enter Address" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Address Link" name="addressLink">
                  <Input className="custom-input" placeholder="Enter Address Link" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Education" name="education">
                  <Input className="custom-input" placeholder="Enter Education" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Marriage Anniversary" name="marriageAnniversary">
                  <DatePicker className="custom-date" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Mobile Number Status" name="mobileNumberStatus">
                  <Select placeholder="Select Mobile Number Status" className="custom-select">
                    <Select.Option value="Show(બતાવો)" selected>
                      Show(બતાવો)
                    </Select.Option>
                    <Select.Option value="Hide( છુપાવો)">Hide( છુપાવો)</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Blood Donate" name="bloodDonate">
                  <Select placeholder="Select Blood Donate" className="custom-select">
                    <Select.Option value="Yes">Yes</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Organ Donate" name="organDonate">
                  <Select placeholder="Select Organ Donate" className="custom-select">
                    <Select.Option value="Yes">Yes</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Eyes Donate" name="eyeDonate">
                  <Select placeholder="Select Eyes Donate" className="custom-select">
                    <Select.Option value="Yes">Yes</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Body Donate" name="bodyDonate">
                  <Select placeholder="Select Body Donate" className="custom-select">
                    <Select.Option value="Yes">Yes</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mt-6 mb-6">
              <Space>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
                <Button
                  danger
                  onClick={() => {
                    navigate('/phonebook')
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  )
}

export default UpsertPhone
