import React, { Suspense } from 'react'
import { ConfigProvider } from 'antd'
import { AppRouter } from 'Router/AppRouter'
import MainLoader from 'Component/Common/MainLoader'

const App = () => {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#1677FF',
            fontFamily: 'Figtree, sans-serif',
          },
        }}
      >
        <Suspense fallback={<MainLoader />}>
          <AppRouter />
        </Suspense>
      </ConfigProvider>
    </>
  )
}

export default App
