import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Form, Input, Col, Row, Space, Upload, type UploadProps, Image, DatePicker, Select } from 'antd'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import { type IAddFeed } from 'Util/Interface'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'

const UpsertFeed: React.FC = () => {
  const [functionImg1, setFunctionImg1] = useState<any>('')
  const [uploadFuntionImg1, setUploadFunctionImg1] = useState<any>('')

  const [functionImg2, setFunctionImg2] = useState<any>('')
  const [uploadFuntionImg2, setUploadFunctionImg2] = useState<any>('')

  const [functionImg3, setFunctionImg3] = useState<any>('')
  const [uploadFuntionImg3, setUploadFunctionImg3] = useState<any>('')

  const [functionImg4, setFunctionImg4] = useState<any>('')
  const [uploadFuntionImg4, setUploadFunctionImg4] = useState<any>('')

  const [functionImg5, setFunctionImg5] = useState<any>('')
  const [uploadFuntionImg5, setUploadFunctionImg5] = useState<any>('')

  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const params = useParams()
  const navigate = useNavigate()

  const fetchById = useCallback(
    async (recordId: string) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/feed/${recordId}`)
        if (response.data?.status && response?.data?.code === 200) {
          dispatch(setLoader(false))
          if (response.data?.data?.functionImage1) {
            setFunctionImg1(response.data?.data?.functionImage1)
          }

          if (response.data?.data?.functionImage2) {
            setFunctionImg2(response.data?.data?.functionImage2)
          }
          if (response.data?.data?.functionImage3) {
            setFunctionImg3(response.data?.data?.functionImage3)
          }
          if (response.data?.data?.functionImage4) {
            setFunctionImg4(response.data?.data?.functionImage4)
          }
          if (response.data?.data?.functionImage5) {
            setFunctionImg5(response.data?.data?.functionImage5)
          }

          form.setFieldsValue({
            eventCity: response.data?.data?.functionCity,
            eventDetails: response.data?.data?.functionDetails,
            eventName: response.data?.data?.functionName,
            eventType: response.data?.data?.eventType,
            eventDate: dayjs(response.data?.data?.functionDate),
          })
        } else {
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        dispatch(setLoader(false))
      }
    },
    [dispatch, form]
  )

  useEffect(() => {
    if (params?.id) {
      void fetchById(params?.id)
    }
  }, [fetchById, params?.id])

  const handleUpsert = async (param: IAddFeed) => {
    try {
      const formValue = new FormData()
      param.eventCity && formValue.append('eventCity', param.eventCity)
      param.eventDetails && formValue.append('eventDetails', param.eventDetails)
      param.eventName && formValue.append('eventName', param.eventName)
      param.eventType && formValue.append('eventType', param.eventType)
      param.eventDate && formValue.append('eventDate', dayjs(param.eventDate).format('YYYY/MM/DD'))

      if (param?.eventImage1) {
        formValue.append('eventImage1', param?.eventImage1[0].originFileObj)
      }
      if (param?.eventImage2) {
        formValue.append('eventImage2', param?.eventImage2[0].originFileObj)
      }
      if (param?.eventImage3) {
        formValue.append('eventImage3', param?.eventImage3[0].originFileObj)
      }
      if (param?.eventImage4) {
        formValue.append('eventImage4', param?.eventImage4[0].originFileObj)
      }
      if (param?.eventImage5) {
        formValue.append('eventImage5', param?.eventImage5[0].originFileObj)
      }

      const response = await Http({
        url: params?.id ? `/feed/${String(params?.id)}` : '/feed',
        method: 'POST',
        data: formValue,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        if (!params?.id) {
          form.resetFields()
        }
        setUploadFunctionImg1('')
        setUploadFunctionImg2('')
        setUploadFunctionImg3('')
        setUploadFunctionImg4('')
        setUploadFunctionImg5('')
        if (params?.id) {
          void fetchById(String(params?.id))
        }
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
      form.resetFields()
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const functionProps1: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadFunctionImg1(info?.file)
    },
  }

  const functionProps2: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadFunctionImg2(info?.file)
    },
  }

  const functionProps3: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadFunctionImg3(info?.file)
    },
  }

  const functionProps4: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadFunctionImg4(info?.file)
    },
  }

  const functionProps5: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadFunctionImg5(info?.file)
    },
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Feed Detail</h3>
              </div>
              <div>
                <Space>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('/feed')
                    }}
                  >
                    Back
                  </Button>
                </Space>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={(value: IAddFeed) => {
              void handleUpsert(value)
            }}
            requiredMark="optional"
          >
            <Row gutter={[40, 24]}>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Event Name"
                  name="eventName"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Event Name!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Event Name" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Event City"
                  name="eventCity"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Event City!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Event City" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Event Details"
                  name="eventDetails"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Event Details!',
                    },
                  ]}
                >
                  <TextArea className="custom-input" placeholder="Enter Event Details" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Event Date"
                  name="eventDate"
                  rules={[
                    {
                      required: true,
                      message: 'Select Event Date!',
                    },
                  ]}
                >
                  <DatePicker className="custom-date" placeholder="Select Event Date" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Event Type"
                  name="eventType"
                  rules={[
                    {
                      required: true,
                      message: 'Select Event Type!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Event Type"
                    className="custom-select"
                    options={[
                      { value: 'event', label: 'Event' },
                      { value: 'hundi', label: 'Hundi' },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Function Image 1"
                      name="eventImage1"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Function Image 1!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...functionProps1}>{uploadFuntionImg1?.name ? <Button>{String(uploadFuntionImg1?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="ml-4">{functionImg1 && <Image width={100} src={`${ATTCHMENT_BASE_URL}${String(functionImg1)}`} />}</div>
                  </Col>
                </Row>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Function Image 2"
                      name="eventImage2"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Function Image 2!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...functionProps2}>{uploadFuntionImg2?.name ? <Button>{String(uploadFuntionImg2?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="ml-4">{functionImg2 && <Image width={100} src={`${ATTCHMENT_BASE_URL}${String(functionImg2)}`} />}</div>
                  </Col>
                </Row>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Function Image 3"
                      name="eventImage3"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Function Image 3!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...functionProps3}>{uploadFuntionImg3?.name ? <Button>{String(uploadFuntionImg3?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="ml-4">{functionImg3 && <Image width={100} src={`${ATTCHMENT_BASE_URL}${String(functionImg3)}`} />}</div>
                  </Col>
                </Row>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Function Image 4"
                      name="eventImage4"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Function Image 4!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...functionProps4}>{uploadFuntionImg4?.name ? <Button>{String(uploadFuntionImg4?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="ml-4">{functionImg4 && <Image width={100} src={`${ATTCHMENT_BASE_URL}${String(functionImg4)}`} />}</div>
                  </Col>
                </Row>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Function Image 5"
                      name="eventImage5"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Function Image 5!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...functionProps5}>{uploadFuntionImg5?.name ? <Button>{String(uploadFuntionImg5?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="ml-4">{functionImg5 && <Image width={100} src={`${ATTCHMENT_BASE_URL}${String(functionImg5)}`} />}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-6 mb-6">
              <Space>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
                <Button
                  danger
                  onClick={() => {
                    navigate('/feed')
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  )
}

export default UpsertFeed
