import React from 'react'
import notFound from '../Assets/svg/error404.svg'
import { Link } from 'react-router-dom'
import Image from 'rc-image'

const NotFound = () => {
  return (
    <>
      <div className="error-wrapper">
        <Image preview={false} src={notFound} alt="404" className="img-fluid" />
        <h2 className="error-heading">Oops!</h2>
        <p className="error-subheading">Sorry, page not found!</p>
        <Link to="/" className="ant-btn ant-btn-link">
          Return to Home
        </Link>
      </div>
    </>
  )
}

export default NotFound
