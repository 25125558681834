import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { setLoader } from 'Store/slices/commonSlice'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { DeleteOutlined } from '@ant-design/icons'
import { toast } from 'sonner'
import Http, { type ApiErrorData } from 'Util/Http'
import { type IEmergency } from 'Util/Interface'

const Emergency: React.FC = () => {
  const [tableData, setTableData] = useState<IEmergency[]>([])

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/emergency`)
      if (response.data?.status && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleDelete = async (params: IEmergency) => {
    try {
      const response = await Http(`/emergency/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const columns: ColumnsType<IEmergency> = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'id',
      render: (value, item, index) => (1 - 1) * 10 + index + 1,
    },
    {
      title: 'Patient Name',
      dataIndex: 'patientName',
      key: 'patientName',
    },
    {
      title: 'Blood Group',
      dataIndex: 'bloodGroup',
      key: 'bloodGroup',
    },
    {
      title: 'Relation',
      dataIndex: 'relation',
      key: 'relation',
    },
    {
      title: 'Hospital Address',
      dataIndex: 'hospitalAddress',
      key: 'hospitalAddress',
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobileNo',
      key: 'mobileNo',
    },
    {
      title: 'Comment',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    // {
    //   title: 'Status',
    //   key: 'status',
    //   dataIndex: 'status',
    //   render: (value) => <Tag color={value === 1 ? 'green' : 'red'}>{value === 1 ? 'Active' : 'Inactive'}</Tag>,
    // },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: IEmergency) => {
        return (
          <Space>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Emergency List</h3>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: IEmergency) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
    </>
  )
}

export default Emergency
