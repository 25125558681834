import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { type AxiosError } from 'axios'
import { toast } from 'sonner'
import { BASE_URL } from 'Util/Constant'
import { type ILoginUser } from 'Util/Interface'
import { setItem } from 'Util/Storage'

interface ServerError {
  message: string
}

export const SignIn = createAsyncThunk('SignIn', async (val: ILoginUser, { dispatch, rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/access`,
      {
        email: val.email,
        password: val.password,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'fWGIJfyhr1fAMuLwSWQRNjcdxCZenUiboGjJQE',
        },
      }
    )
    if (response?.data && response?.data?.status) {
      setItem('access-token', response.data?.token)
      axios.defaults.headers.common['access-token'] = `Bearer ${String(response.data?.token)}`
      toast.success(response?.data?.message || 'You have logged in successfully.')
      return response.data
    } else {
      toast.error(response?.data?.message || 'Something went wrong')
    }
  } catch (error) {
    const err = error as AxiosError<ServerError>
    toast.error(err?.response?.data?.message ?? 'Something went wrong')
    rejectWithValue(error)
  }
})
