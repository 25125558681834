import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Form, Input, InputNumber, Select, Col, Row, Space, Upload, type UploadProps, Image, DatePicker, type UploadFile } from 'antd'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import { type IAddBusiness } from 'Util/Interface'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'

const UpsertBusiness: React.FC = () => {
  const [profileImg, setProfileImg] = useState<any>('')
  const [uploadProfileImg, setUploadProfileImg] = useState<any>('')
  const [categoryList, setBusinessCategory] = useState([])
  const [form] = Form.useForm()

  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()

  const params = useParams()
  const navigate = useNavigate()

  const fetchById = useCallback(
    async (recordId: string) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/bussiness/${recordId}`)
        if (response.data?.status && response?.data?.code === 200) {
          dispatch(setLoader(false))
          const file: UploadFile[] = []

          if (response.data?.data?.mainImage) {
            setProfileImg(response.data?.data?.mainImage)
          }
          response?.data?.data?.business_image?.map((ele: { id: string; imageName: string; imagePath: string }) => {
            file.push({
              uid: ele.id,
              name: ele.imageName,
              status: 'done',
              url: `${ATTCHMENT_BASE_URL}${ele?.imagePath}`,
            })
            return true
          })
          setFileList(file)

          form.setFieldsValue({
            familyId: response.data?.data?.familyId,
            businessName: response.data?.data?.businessName,
            businessMobile: response.data?.data?.businessMobileNo,
            businessAddress: response.data?.data?.businessAddress,
            businessEmail: response.data?.data?.businessEmail,
            businessWebsite: response.data?.data?.businessWebsite,
            businessDescription: response.data?.data?.businessDescription,
            businessCategory: response.data?.data?.businessCategory,
            partner: response.data?.data?.partner,
            ownerName1: response.data?.data?.ownerName1,
            ownerMobile1: response.data?.data?.ownerMobileNo1,
            ownerEmail1: response.data?.data?.ownerEmail1,
            ownerName2: response.data?.data?.ownerName2,
            ownerMobile2: response.data?.data?.ownerMobileNo2,
            ownerEmail2: response.data?.data?.ownerEmail2,
            ownerName3: response.data?.data?.ownerName3,
            ownerMobile3: response.data?.data?.ownerMobileNo3,
            ownerEmail3: response.data?.data?.ownerEmail3,
            addressLink: response.data?.data?.businessAddressLink,
            donationReceipt: response.data?.data?.donationReceiptNo,
            donationAmount: response.data?.data?.donationAmount,
            startDate: dayjs(response.data?.data?.startDate),
            endDate: dayjs(response.data?.data?.endDate),
          })
        } else {
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        dispatch(setLoader(false))
      }
    },
    [dispatch, form]
  )

  const fetchMaster = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http('/admin-master')
      if (response.data?.status && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setBusinessCategory(response.data?.data?.businesscategory)
      } else {
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (params?.id) {
      void fetchById(params?.id)
    }
    void fetchMaster()
  }, [fetchById, fetchMaster, params?.id])

  const handleUpsert = async (param: IAddBusiness) => {
    try {
      const formValue = new FormData()
      param.familyId && param.familyId !== 'null' && formValue.append('familyId', param.familyId)
      param.businessName && param.businessName !== 'null' && formValue.append('businessName', param.businessName)
      param.businessMobile && param.businessMobile !== 'null' && formValue.append('businessMobile', param.businessMobile)
      param.businessAddress && param.businessAddress !== 'null' && formValue.append('businessAddress', param.businessAddress)
      param.businessEmail && param.businessEmail !== 'null' && formValue.append('businessEmail', param.businessEmail)
      param?.businessWebsite && param.businessWebsite !== 'null' && formValue.append('businessWebsite', param.businessWebsite)
      param.businessDescription && param.businessDescription !== 'null' && formValue.append('businessDescription', param.businessDescription)
      param.businessCategory && param.businessCategory !== 'null' && formValue.append('businessCategory', param.businessCategory)
      param.partner && param.partner !== 'null' && formValue.append('partner', param.partner)
      param.ownerName1 && param.ownerName1 !== 'null' && formValue.append('ownerName1', param.ownerName1)
      param.ownerMobile1 && param.ownerMobile1 !== 'null' && formValue.append('ownerMobile1', param.ownerMobile1)
      param.ownerEmail1 && param.ownerEmail1 !== 'null' && formValue.append('ownerEmail1', param.ownerEmail1)
      param?.ownerName2 && param.ownerName2 !== 'null' && formValue.append('ownerName2', param.ownerName2)
      param?.ownerMobile2 && param.ownerMobile2 !== 'null' && formValue.append('ownerMobile2', param.ownerMobile2)
      param?.ownerEmail2 && param.ownerEmail2 !== 'null' && formValue.append('ownerEmail2', param.ownerEmail2)
      param?.ownerName3 && param.ownerName3 !== 'null' && formValue.append('ownerName3', param.ownerName3)
      param?.ownerMobile3 && param.ownerMobile3 !== 'null' && formValue.append('ownerMobile3', param.ownerMobile3)
      param?.ownerEmail3 && param.ownerEmail3 !== 'null' && formValue.append('ownerEmail3', param.ownerEmail3)
      param?.addressLink && param.addressLink !== 'null' && formValue.append('addressLink', param.addressLink)
      param.donationReceipt && param.donationReceipt !== 'null' && formValue.append('donationReceipt', param.donationReceipt)
      param.donationAmount && param.donationAmount !== 'null' && formValue.append('donationAmount', param.donationAmount)
      param.startDate && param.startDate !== 'null' && formValue.append('startDate', dayjs(param.startDate).format('YYYY/MM/DD'))
      param.endDate && param.endDate !== 'null' && formValue.append('endDate', dayjs(param.endDate).format('YYYY/MM/DD'))

      if (param?.mainImage) {
        formValue.append('bannerImage', param?.mainImage[0].originFileObj)
      }

      if (param.businessImage) {
        param?.businessImage?.fileList?.map((item: any) => {
          if (item?.originFileObj) {
            formValue.append('businessImage[]', item?.originFileObj)
          }
          return item
        })
      }

      const response = await Http({
        url: params?.id ? `/bussiness/${String(params?.id)}` : '/bussiness',
        method: 'POST',
        data: formValue,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        if (!params?.id) {
          form.resetFields()
        }
        setUploadProfileImg('')
        setFileList([])
        if (params?.id) {
          void fetchById(String(params?.id))
        }
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleDelete = async (recordId: string) => {
    try {
      const response = await Http(`/media/${String(recordId)}`, {
        method: 'DELETE',
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const profileProps: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadProfileImg(info?.file)
    },
  }

  const bannerProps: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 5,
    className: 'avatar-uploader',
    multiple: true,
    listType: 'picture-card',
    showUploadList: true,
    fileList,
    // onPreview: () => {
    //   console.log('')
    // },
    onChange: (info) => {
      setFileList(info.fileList)
      setIsLoading(true)
      if (info.file.status === 'removed') {
        void handleDelete(info?.file?.uid)
        setIsLoading(false)
      }
      if (info.file.status === 'done') {
        setIsLoading(false)
      }
      if (info.file.status === 'error') {
        setIsLoading(false)
        toast.error(info?.file?.error)
      }
    },
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: ({ file, onSuccess }) => {
      if (onSuccess && file) {
        onSuccess(file)
      }
    },
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Business Detail</h3>
              </div>
              <div>
                <Space>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('/business')
                    }}
                  >
                    Back
                  </Button>
                </Space>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={(value: IAddBusiness) => {
              void handleUpsert(value)
            }}
            requiredMark="optional"
          >
            <Row gutter={[40, 24]}>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Family Id"
                  name="familyId"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Family Id!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Family Id" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Business Name"
                  name="businessName"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Business Name!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Business Name" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Business Mobile"
                  name="businessMobile"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Business Mobile!',
                    },
                  ]}
                >
                  <InputNumber className="custom-input-number" minLength={10} maxLength={10} placeholder="Enter Mobile Number" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Business Address"
                  name="businessAddress"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Business Address!',
                    },
                  ]}
                >
                  <TextArea className="custom-input" placeholder="Enter Business Address" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Business Email Address"
                  name="businessEmail"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Business Email Address!',
                      type: 'email',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Business Email Address" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Business Website"
                  name="businessWebsite"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please Enter Business Website!',
                  //   },
                  // ]}
                >
                  <Input className="custom-input" placeholder="Enter Business Website" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Business Description"
                  name="businessDescription"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Business Description!',
                    },
                  ]}
                >
                  <TextArea className="custom-input" placeholder="Enter Business Description" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Business Category"
                  name="businessCategory"
                  rules={[
                    {
                      required: true,
                      message: 'Select Business Category!',
                    },
                  ]}
                >
                  <Select placeholder="Select Business Category" className="custom-select">
                    {categoryList?.map((item: { businessCategory: string }, idx: number) => {
                      return (
                        <Select.Option value={item?.businessCategory} key={idx}>
                          {item?.businessCategory}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Partner/ Proprieter"
                  name="partner"
                  rules={[
                    {
                      required: true,
                      message: 'Select Partner/ Proprieter!',
                    },
                  ]}
                >
                  <Select placeholder="Select Partner/ Proprieter" className="custom-select">
                    <Select.Option value="Proprietorship(માલિકી)" selected>
                      Proprietorship(માલિકી)
                    </Select.Option>
                    <Select.Option value="Partnership(ભાગીદારી)">Partnership(ભાગીદારી)</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Owner Name 1"
                  name="ownerName1"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Owner Name 1!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Owner Name 1" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Owner Mobile 1"
                  name="ownerMobile1"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Owner Mobile 1!',
                    },
                  ]}
                >
                  <InputNumber className="custom-input-number" min={0} minLength={10} maxLength={10} placeholder="Enter Owner Mobile 1" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Owner Email 1"
                  name="ownerEmail1"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Owner Email 1!',
                      type: 'email',
                    },
                  ]}
                >
                  <TextArea className="custom-input" placeholder="Enter Owner Email 1" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Owner Name 2" name="ownerName2">
                  <Input className="custom-input" placeholder="Enter Owner Name 2" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Owner Mobile 2" name="ownerMobile2">
                  <InputNumber className="custom-input-number" min={0} minLength={10} maxLength={10} placeholder="Enter Owner Mobile 2" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Owner Email 2"
                  name="ownerEmail2"
                  rules={[
                    {
                      message: 'Please Enter Business Email Address!',
                      type: 'email',
                    },
                  ]}
                >
                  <TextArea className="custom-input" placeholder="Enter Owner Email 2" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Owner Name 3" name="ownerName3">
                  <Input className="custom-input" placeholder="Enter Owner Name 3" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Owner Mobile 3" name="ownerMobile3">
                  <InputNumber className="custom-input-number" min={0} minLength={10} maxLength={10} placeholder="Enter Owner Mobile 3" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Owner Email 3"
                  name="ownerEmail3"
                  rules={[
                    {
                      message: 'Please Enter Business Email Address!',
                      type: 'email',
                    },
                  ]}
                >
                  <TextArea className="custom-input" placeholder="Enter Owner Email 3" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Business Address Link" name="addressLink">
                  <Input className="custom-input" placeholder="Enter Business Address Link" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Donation Receipt No"
                  name="donationReceipt"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Donation Receipt No!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Donation Receipt No" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Donation Amount"
                  name="donationAmount"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Donation Amount!',
                    },
                  ]}
                >
                  <InputNumber className="custom-input-number" min={0} placeholder="Enter Donation Amount" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: 'Select Start Date!',
                    },
                  ]}
                >
                  <DatePicker className="custom-date" placeholder="Select Start Date" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: 'Select End Date!',
                    },
                  ]}
                >
                  <DatePicker className="custom-date" placeholder="Select End Date" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Banner Image"
                      name="mainImage"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Banner Image!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...profileProps}>{uploadProfileImg?.name ? <Button>{String(uploadProfileImg?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="ml-4">{profileImg && <Image width={100} src={`${ATTCHMENT_BASE_URL}${String(profileImg)}`} />}</div>
                  </Col>
                </Row>
              </Col>

              <Col lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Business Images (Upload Multiple Max 5)"
                      name="businessImage"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Business Images!',
                        },
                      ]}
                    >
                      <Upload {...bannerProps} className="banner-upload">
                        <div>{isLoading ? <LoadingOutlined /> : <PlusOutlined />}</div>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-6 mb-6">
              <Space>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
                <Button
                  danger
                  onClick={() => {
                    navigate('/bussiness')
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  )
}

export default UpsertBusiness
