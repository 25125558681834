import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from '../store'
import { SignIn } from 'Store/services/auth.service'
import { clearItem } from 'Util/Storage'

const initialState = {
  isLoading: false,
  isSuccess: false,
  token: localStorage.getItem('access-token') ? (localStorage.getItem('access-token') as string) : null,
  user: null,
  message: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      clearItem()
      localStorage.removeItem('persist:root')
      state.user = null
      state.token = null
      state.message = 'Logout Successfully'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SignIn.pending, (state, { payload }) => {
        state.isLoading = true
        state.isSuccess = false
        state.message = 'Please Wait!'
      })
      .addCase(SignIn.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = payload?.user
        state.token = payload?.token
        state.message = 'You have logged in successfully'
      })
      .addCase(SignIn.rejected, (state: any, { payload }: any) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = 'Login Failed'
        state.token = null
      })
  },
})

export const { logout } = authSlice.actions

export const authSelector = (state: RootState) => state.auth

export default authSlice.reducer
