import CryptoJS from 'crypto-js'

const PASSWORD = 'D_iW(_Xt=U(00NJNL+Ba7)L0Fa]qFj'

export const setItem = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value)
    return 0
  } catch (error) {
    return 1
  }
}

export const getItem = (key: string) => {
  try {
    if (!localStorage.getItem(key)) {
      return null
    }
    const data = localStorage.getItem(key) as string
    if (!data) {
      return null
    }
    return data
  } catch (error) {
    clearItem()
  }
}

export const setItemEncrpt = (key: string, value: string) => {
  try {
    const encryptedValue = encrypt(value, PASSWORD)
    localStorage.setItem(key, encryptedValue)
    return 0
  } catch (error) {
    return 1
  }
}

export const getItemEncrpt = (key: string) => {
  try {
    if (!localStorage.getItem(key)) {
      return null
    }
    const data = decrypt(localStorage.getItem(key) as string, PASSWORD)
    if (!data) {
      return null
    }
    return data
  } catch (error) {
    clearItem()
  }
}

export const removeItem = (key: string) => {
  localStorage.removeItem(key)
}

export const clearItem = () => {
  localStorage.clear()
}

export const reload = () => {
  window.location.reload()
}

const encrypt = (data: string, password: string) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(data, password)
    const transitmessage = encrypted.toString()
    return transitmessage
  } catch (error) {
    return ''
  }
}

const decrypt = (encryptedData: string, password: string) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, password).toString(CryptoJS.enc.Utf8)
    return decrypted
  } catch (error) {
    return ''
  }
}
