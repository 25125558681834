export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface IUpdatePassword {
  password: string
  password_confirmation: string
  old_password: string
}

export interface ILoginUser {
  email: string
  password: string
}

export interface IPagination {
  limit?: number
  page?: number
  skip?: number
  total?: number
}

export interface IAppFeature {
  id: string
  featureName: string
  imgUrl: string
  screenName: string
  externallink: any
  status: number
  created_at: string
  updated_at: string
}

export interface IMagzine {
  id: string
  magnizeName: string
  magzineImg: string
  magnizePdf: string
  status: number
  created_at: string
  updated_at: string
}

export interface IAddMagzine {
  id: string
  magnizeName: string
  magzineImg: string
  magzinePdf: any
  status: number
  created_at: string
  updated_at: string
}

export interface INewsPaper {
  id: string
  sourceName: string
  image: string
  link: string
  status: number
  created_at: string
  updated_at: string
}

export interface IAddNewsPaper {
  id: string
  sourceName: string
  image: any
  link: string
  status: number
  created_at: string
  updated_at: string
}

export interface INotification {
  id: string
  title: string
  topic: any
  message: string
  userId: any
  notificationImage: string
  notificationType: string
  birthAnniversaryUsersObj: any
  samaj: any
  created_at: string
  updated_at: string
}

export interface IAddNotification {
  title: string
  message: string
  notificationImage: any
  notificationType: string
}

export interface IEmergency {
  id: string
  title: string
  height: number
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export interface IFeedback {
  id: string
  feedback: string
  userId: string
  file: any
  type: string
  created_at: string
  updated_at: string
  user: IPhoneBook
}

export interface IExplore {
  id: string
  postImg: string
  postDescription: string
  status: number
  userId: string
  created_at: string
  updated_at: string
}

export interface IReceipe {
  id: string
  receipeName: string
  receipeProcess: string
  receipeDescription: string
  preprationTime: string
  youtubeLink: any
  status: number
  userId: string
  created_at: string
  updated_at: string
  receipe_like_count: number
  receipe_like: any[]
  receipe_user: ReceipeUser
  receipe_image: ReceipeImage[]
}

export interface ReceipeUser {
  id: string
  samaj: string
  name: string
  middleName: string
  surName: string
  profileImage: string
}

export interface ReceipeImage {
  id: string
  imageName: string
  imagePath: string
  receipeId: string
  created_at: string
  updated_at: string
}

export interface IJobVacancy {
  id: string
  jobTitle: string
  CompanyName: string
  description: string
  phoneNo: string
  jobImage: string
  address: string
  city: string
  state: string
  status: number
  userId: string
  created_at: string
  updated_at: string
}

export interface IEducation {
  id: string
  education: string
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export interface IMartialStatus {
  id: string
  title: string
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export interface ISubVichar {
  id: string
  qoute: string
  author: string
  status: number
  created_at: string
  updated_at: string
}

export interface ISong {
  id: string
  qoute: string
  author: string
  status: number
  created_at: string
  updated_at: string
}

export interface IAddSong {
  artist: string
  songName: string
  albumArt: any
  song: any
  songType: string
}

export interface IFeed {
  id: string
  functionName: string
  functionDetails: string
  functionDate: string
  functionCity: string
  functionImage1: string
  functionImage2: string
  functionImage3: string
  functionImage4: string
  functionImage5: string
  eventVideo: any
  created_at: string
  updated_at: string
}

export interface IAddFeed {
  id: string
  eventName: string
  eventDetails: string
  eventDate: string
  eventCity: string
  eventType: string
  eventImage1: any
  eventImage2: any
  eventImage3: any
  eventImage4: any
  eventImage5: any
  eventVideo: any
  created_at: string
  updated_at: string
}

export interface IBusiness {
  id: string
  familyId: string
  businessName: string
  businessMobileNo: string
  businessAddress: string
  businessEmail: string
  businessWebsite: string
  businessDescription: string
  businessCategory: string
  partner: string
  ownerName1: string
  ownerMobileNo1: string
  ownerEmail1: string
  ownerName2: string
  ownerMobileNo2: string
  ownerEmail2: string
  ownerName3: string
  ownerMobileNo3: string
  ownerEmail3: string
  businessAddressLink: string
  donationReceiptNo: string
  donationAmount: string
  mainImage: string
  startDate: string
  endDate: string
  status: number
  created_at: string
  updated_at: string
}

export interface IAddBusiness {
  familyId: string
  businessName: string
  businessMobile: string
  businessAddress: string
  businessEmail: string
  businessWebsite: string
  businessDescription: string
  businessCategory: string
  partner: string
  ownerName1: string
  ownerMobile1: string
  ownerEmail1: string
  ownerName2: string
  ownerMobile2: string
  ownerEmail2: string
  ownerName3: string
  ownerMobile3: string
  ownerEmail3: string
  addressLink: string
  donationReceipt: string
  donationAmount: string
  startDate: string
  endDate: string
  mainImage: any
  businessImage: any
  // userId: string
}

export interface IPhoneBook {
  id: string
  samaj: string
  familyId: string
  name: string
  middleName: string
  surName: string
  bloodGroup: string
  gender: string
  dateOfBirth: string
  mobileNumber: string
  email: string
  relationShip: string
  occupation: string
  helpBlood: string
  donateOrgans: string
  donateEyes: string
  donateBody: string
  mobileNumberStatus: string
  address: string
  addressLink: string
  marriageAnniversary: string
  education: string
  nativePlace: string
  nativePlaceTaluka: string
  nativePlaceDistrict: string
  maternalSurname: string
  maternalVillage: string
  maternalVillageTaluka: string
  maternalVillageDistrict: any
  businessJobName: string
  businessAddress: string
  businessMobile: string
  businessDescription: string
  businessEmail: string
  website: string
  profileImage: string
  status: number
  remember_token: any
  created_at: string
  updated_at: string
}

export interface IAddPhoneBook {
  id: string
  samaj: string
  familyId: string
  firstName: string
  middleName: string
  surName: string
  bloodGroup: string
  gender: string
  dateofBirth: string
  mobileNumber: string
  emailAddress: string
  relation: string
  occupation: string
  bloodDonate: string
  organDonate: string
  eyeDonate: string
  bodyDonate: string
  mobileNumberStatus: string
  address: string
  addressLink: string
  marriageAnniversary: string
  education: string
  nativePlace: string
  nativePlaceTaluka: string
  nativePlaceDistrict: string
  maternalSurname: string
  maternalVillage: string
  maternalVillageTaluka: string
  maternalVillageDistrict: any
  businessName: string
  businessAddress: string
  businessMobile: string
  businessDescription: string
  businessEmail: string
  businessWebsite: string
  profileImage: any
  status: number
  remember_token: any
  created_at: string
  updated_at: string
}

export interface IAppVersion {
  id: string
  appVersion: string
  status: string
  created_at: string
  updated_at: string
  recordId: string
}

export interface IGuestUser {
  id: string
  firstname: string
  middlename: string
  lastname: string
  phone: string
  email: string
  gender: string
  city: string
  created_at: string
  updated_at: string
}

export interface IAdvertisement {
  id: string
  banner: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddAdvertisement {
  id: string
  thumbnail: any
  status: string
}

export interface IDashboard {
  business: number
  city: number
  emergency: number
  event: number
  explore: number
  job: number
  receipes: number
  relationship: number
  songs: number
  user: number
}
