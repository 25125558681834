import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { setLoader } from 'Store/slices/commonSlice'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { DeleteOutlined } from '@ant-design/icons'
import { toast } from 'sonner'
import Http, { type ApiErrorData } from 'Util/Http'
import { type IJobVacancy } from 'Util/Interface'

const JobVacancy: React.FC = () => {
  const [tableData, setTableData] = useState<IJobVacancy[]>([])

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/job-vacancy`)
      if (response.data?.status && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleDelete = async (params: IJobVacancy) => {
    try {
      const response = await Http(`/job-vacancy/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const columns: ColumnsType<IJobVacancy> = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'id',
      render: (value, item, index) => (1 - 1) * 10 + index + 1,
    },
    {
      title: 'Job Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'Company Name',
      dataIndex: 'CompanyName',
      key: 'CompanyName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Phone No',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 1 ? 'green' : 'red'}>{value === 1 ? 'Active' : 'Inactive'}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: IJobVacancy) => {
        return (
          <Space>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Job Vacancy List</h3>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: IJobVacancy) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
    </>
  )
}

export default JobVacancy
