import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag, Image } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { setLoader } from 'Store/slices/commonSlice'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { DeleteOutlined } from '@ant-design/icons'
import { toast } from 'sonner'
import Http, { type ApiErrorData } from 'Util/Http'
import { type IReceipe } from 'Util/Interface'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'

const Receipe: React.FC = () => {
  const [tableData, setTableData] = useState<IReceipe[]>([])

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/admin-receipe`)
      if (response.data?.status && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleDelete = async (params: IReceipe) => {
    try {
      const response = await Http(`/admin-receipe/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const columns: ColumnsType<IReceipe> = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'id',
      render: (value, item, index) => (1 - 1) * 10 + index + 1,
    },
    {
      title: 'Receipe Name',
      dataIndex: 'receipeName',
      key: 'receipeName',
    },
    {
      title: 'Receipe Description',
      dataIndex: 'receipeDescription',
      key: 'receipeDescription',
    },
    {
      title: 'Prepration Time',
      dataIndex: 'preprationTime',
      key: 'preprationTime',
    },
    {
      title: 'Total Like',
      dataIndex: 'receipe_like_count',
      key: 'receipe_like_count',
    },
    {
      title: 'User',
      dataIndex: 'receipe_user',
      key: 'receipe_user',
      render: (value, record: IReceipe) => {
        return <div>{record?.receipe_user?.name}</div>
      },
    },
    {
      title: 'Receipe Image',
      dataIndex: 'receipe_image',
      key: 'receipe_image',
      render: (value, record: IReceipe) => {
        return (
          <>
            {record?.receipe_image[0]?.imagePath ? (
              <Image src={`${ATTCHMENT_BASE_URL}${String(record?.receipe_image[0]?.imagePath)}`} alt="profile" height={100} width={100} />
            ) : (
              <div style={{ background: 'grey', height: 100, width: 100 }}></div>
            )}
          </>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 1 ? 'green' : 'red'}>{value === 1 ? 'Active' : 'Inactive'}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: IReceipe) => {
        return (
          <Space>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Receipe List</h3>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: IReceipe) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
    </>
  )
}

export default Receipe
