import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import RequireAuth from './RequireAuth'
import MainLayout from '../Component/MainLayout/MainLayout'
import LoginPage from '../Pages/LoginPage'
import NotFound from '../Pages/NotFound'
import MainLoader from 'Component/Common/MainLoader'
import GuestUsers from '../Pages/GuestUsers'
import UserDetail from '../Component/User/UserDetail'
import AppFeature from '../Pages/AppFeature'
import Business from '../Pages/Business'
import Emergency from '../Pages/Emergency'
import Explore from '../Pages/Explore'
import Feed from '../Pages/Feed'
import Feedback from '../Pages/Feedback'
import JobVacancy from '../Pages/JobVacancy'
import Magzine from '../Pages/Magzine'
import PhoneBook from '../Pages/PhoneBook'
import Receipe from '../Pages/Receipe'
import SubVichar from '../Pages/SubVichar'
import Songs from '../Pages/Songs'
import UpsertPhone from '../Component/User/UpsertPhone'
import UpsertFeed from '../Component/Feed/UpsertFeed'
import UpsertBusiness from '../Component/Business/UpsertBusiness'
import Notification from '../Pages/Notification'
import Newspaper from '../Pages/Newspaper'
import Profile from '../Pages/Profile'

const Dashboard = lazy(async () => await import('../Pages/Dashboard'))

// no lazy loading for auth pages to avoid flickering
const Logout = React.lazy(async () => await import('./Logout'))

export const DASHBOARD_PATH = '/'

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  )

  return (
    <BrowserRouter>
      <Suspense fallback={<MainLoader />}>
        <Routes>
          <Route path={DASHBOARD_PATH} element={protectedLayout}>
            <Route index element={<Dashboard />} />
            <Route path="/phonebook" element={<PhoneBook />} />
            <Route path="/phonebook/:id" element={<UserDetail />} />
            <Route path="/phonebook/add" element={<UpsertPhone />} />
            <Route path="/phonebook/edit/:id" element={<UpsertPhone />} />
            <Route path="/bussiness" element={<Business />} />
            <Route path="/bussiness/add" element={<UpsertBusiness />} />
            <Route path="/bussiness/edit/:id" element={<UpsertBusiness />} />
            <Route path="/feature" element={<AppFeature />} />
            <Route path="/feed" element={<Feed />} />
            <Route path="/feed/:id" element={<UpsertFeed />} />
            <Route path="/feed/add" element={<UpsertFeed />} />
            <Route path="/song" element={<Songs />} />
            <Route path="/magzine" element={<Magzine />} />
            <Route path="/newspaper" element={<Newspaper />} />
            <Route path="/emergency" element={<Emergency />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/subvichar" element={<SubVichar />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/job" element={<JobVacancy />} />
            <Route path="/receipe" element={<Receipe />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/guest-user" element={<GuestUsers />} />
            <Route path="/setting" element={<Profile />} />
          </Route>

          <Route path="login" element={<LoginPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
